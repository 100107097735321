import { t } from "i18next";
import useRoleHooks from "Routers/role-hooks";
import Button from "Components/Button/button";
import { useGeneralHooks } from "Pages/general.hooks";
import { useGetGroupByIdQuery } from "Services/groupsApi";
import { EmployeeItem, Header, Loading } from "Components";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import GroupDetails from "Components/GroupDetails/groupDetails";
import { ButtonTypes, HeaderTypes } from "Components/interfaces";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import styles from "./groupById.module.scss";

const GroupById: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const { params, largeScreen, navigate } = useGeneralHooks();
  const { data: groupById, isLoading } = useGetGroupByIdQuery(params.id);
  const { currentRole } = useRoleHooks();

  return (
    <>
      {!largeScreen ? (
        <>
          <Header
            type={HeaderTypes.Navigation}
            navTitle={t("Groups.Groups")}
            iconOnClick={() => navigate(-1)}
            headerStyle={styles.header}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <div className={styles["mobile-group-content"]}>
              <div className={styles["group-info-container"]}>
                <p className={styles["title"]}>
                  {t("Groups.Group_By_Id_Title")}
                </p>
                <GroupDetails
                  name={groupById?.result?.name}
                  teamLeads={"Tommy Jaason"}
                  groupMembersCount={groupById?.result?.groupMembers?.length}
                />
                <div className={styles["group-users-title"]}>
                  {t("Groups.Team_Members")}
                </div>
                <div className={styles["group-members-cont"]}>
                  {groupById?.result?.groupMembers?.map((item, i) => {
                    return (
                      <EmployeeItem
                        src={item.user.avatar}
                        key={item.i}
                        name={`${item.user.name} ${item.user.surname}`}
                        onClick={() =>
                          navigate(`/main/employees/${item.userId}`)
                        }
                      />
                    );
                  })}
                </div>
              </div>
              {currentRole === "HR" ||
                (currentRole === "CEO" && (
                  <Button
                    buttonType={ButtonTypes.Primery}
                    buttonStyle={styles.button}
                    title={t("Groups.Add_Member")}
                    type="submit"
                    onClick={() => navigate("addMember")}
                  />
                ))}
            </div>
          )}
        </>
      ) : (
        <div className={styles["group-page"]}>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className={styles["searchContainer"]}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
              <div className={styles["heading"]}>
                <p className={styles["title"]}>
                  {t("Groups.Group_By_Id_Title")}
                </p>
                <div>
                  {currentRole === "HR" ||
                    (currentRole === "CEO" && (
                      <Button
                        buttonType={ButtonTypes.Primery}
                        buttonStyle="width: 28px;"
                        title={t("Groups.Add_Member")}
                        type="submit"
                        onClick={() => navigate("addMember")}
                      />
                    ))}
                </div>
              </div>
              <div className={styles["group-info-container"]}>
                <GroupDetails
                  name={groupById?.result?.name}
                  teamLeads={"Tommy Jaason"}
                  groupMembersCount={groupById?.result?.groupMembers?.length}
                />
                <div className={styles["group-members-cont"]}>
                  {groupById?.result?.groupMembers?.map((item) => {
                    return (
                      <EmployeeItem
                        key={item.id}
                        src={item.user.avatar}
                        name={`${item.user.name} ${item.user.surname}`}
                        onClick={() =>
                          navigate(`/main/employees/${item.userId}`)
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GroupById;
